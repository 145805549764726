/* eslint-disable */
import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';
import Cover from '../../../components/Cover';

import SEO from '../../../components/SEO';
import { MainLayout } from '../../../components/Layout';
import ArrowRight from '../../../components/Icon/ArrowRight.svg';
import withI18next from '../../../components/withI18next';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { formatDate, isBrowser } from '../../../utils';
import Container from '../../../components/Container';
import Card from '../../../components/Card';

const DEFAULT_MILESTONE_DISTANCE = 75;
const COLUMN_PADDING_LEFT = '10%';
const COLUMN_PADDING = `5% 0 0 ${COLUMN_PADDING_LEFT}`;

const milestoneImageStyle = {
  width: '10vw',
  height: '13vw',
  maxWidth: 150,
  maxHeight: 200
};

function OurStory({ data, pageContext }) {
  const w = useWindowSize();
  const {
    cover,
    whatWeKnow,
    whatWeDo,
    whatWeAimFor,
    milestones
  } = data.gcms.pageAboutUsOurStories[0];
  const { title, keywords, description } = data.gcms.seos[0];
  const [swiperRef, setSwiperRef] = useState(null);

  // for mile stone
  const [milestoneDistance, setMilestoneDistance] = useState(
    DEFAULT_MILESTONE_DISTANCE
  );
  const [activeMilestonePosition, setActiveMilestonePosition] = useState(
    milestones.length - 1
  );
  const dates = milestones.map(m => formatDate(m.date, true));

  useEffect(() => {
    if (isBrowser()) {
      const milestoneRemainWidth = w.innerWidth * 0.9; // padded left 10%
      if (w.innerWidth > 1366) setMilestoneDistance(milestoneRemainWidth / 5);
      else if (w.innerWidth > 1024)
        setMilestoneDistance(milestoneRemainWidth / 3);
      else if (w.innerWidth <= 768 && swiperRef)
        swiperRef.slideTo(milestones.length - 1);
    }
  }, [milestones.length, swiperRef, w.innerWidth]);

  return (
    <MainLayout>
      <SEO
        title={title}
        keywords={keywords}
        description={description}
        meta={pageContext.meta}
      />
      <Container>
        <OurStoryRow>
          <Column fluid>
            <OurStoryImage cover={{ wide: cover }} />
          </Column>
          <Column>
            <OurStoryDetail
              slogan={whatWeKnow.slogan}
              title={whatWeKnow.title}
              description={whatWeKnow.descriptions[0].text}
            />
          </Column>
        </OurStoryRow>

        <WhatWeDid>
          <WhatWeDo>
            <Card
              icon={whatWeDo.order}
              title={whatWeDo.title}
              description={whatWeDo.descriptions[0].text}
            />
          </WhatWeDo>
          <WhatWeAimFor>
            <Card
              icon={whatWeAimFor.order}
              title={whatWeAimFor.title}
              description={whatWeAimFor.descriptions[0].text}
            />
          </WhatWeAimFor>
        </WhatWeDid>

        <div style={{ marginBottom: '4em' }} />
      </Container>
    </MainLayout>
  );
}

const fadeInAnimation = keyframes`${fadeIn}`;

const FadeIn = styled.div`
  animation: ${fadeInAnimation} 2s;
`;

const swipeUpAnimation = keyframes`
  0% {
    transform: translateY(${milestoneImageStyle.width});
  }

  100% {
    transform: translateY(0);
  }
`;

const TheMilestonses = styled.span`
  color: ${({ theme }) => theme.semiBlack};
  transform: rotate(-90deg);
  text-transform: uppercase;
  font-weight: 700;
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: right;
  top: -10%;

  @media screen and (max-width: 1366px) {
    top: -7%;
  }

  @media screen and (max-width: 1024px) {
    top: 9%;
    left: -45px;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const Year = styled.span`
  font-family: 'San Francisco Text', sans-serif;
  font-size: 1.5em;
  color: ${({ theme }) => theme.brand};
  font-weight: 700;
  position: absolute;
  right: -105%;
  width: 100%;
  top: 0;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const ShortDescription = styled(Year)`
  font-size: 1.125em;
  color: black;
  opacity: 0.4;
  font-weight: 300;
  position: absolute;
  right: -105%;
  top: 2.1em;
  width: 100%;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const LongDescription = styled.p`
  font-family: 'San Francisco Text', sans-serif;
  font-size: 3em;
  line-height: 1;
  color: ${({ theme }) => theme.semiBlack};
  font-weight: 700;
  position: absolute;
  right: -205%;
  top: 20%;
  width: 200%;
  height: 50%;

  @media screen and (max-width: 1024px) {
    width: 150%;
    right: -155%;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const MilestoneWrapper = styled.div`
  width: ${milestoneImageStyle.width};
  min-width: 150px;
  height: ${milestoneImageStyle.height} !important;
  max-width: ${milestoneImageStyle.maxWidth}px;
  max-height: ${milestoneImageStyle.maxHeight}px;
  background-size: contain;
  cursor: pointer;
  align-self: flex-end;
  transition: max-width 1s !important;
  position: relative;

  @media screen and (max-width: 1366px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 1024px) {
    font-size: 0.8rem;

    &:last-child,
    &:nth-last-child(2),
    &:nth-last-child(3) {
      ${LongDescription} {
        left: -155% !important;
        text-align: right;
      }

      ${TheMilestonses} {
        left: unset;
        right: -100% !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    height: auto !important;
    max-height: unset;
  }

  &:last-child,
  &:nth-last-child(2),
  &:nth-last-child(3) {
    ${ShortDescription},
    ${Year} {
      left: -105%;
      text-align: right;
    }

    ${TheMilestonses} {
      left: unset;
      right: -117%;
    }

    ${LongDescription} {
      left: -205%;
      text-align: right;
    }
  }

  ${({ active }) =>
    active &&
    css`
      @media screen and (min-width: 769px) {
        animation: ${swipeUpAnimation} 1s ease;
        width: calc(${milestoneImageStyle.width} * 2);
        height: calc(${milestoneImageStyle.height} * 2) !important;
        max-width: ${milestoneImageStyle.maxWidth * 2}px;
        max-height: ${milestoneImageStyle.maxHeight * 2}px;
      }

      @media screen and (max-width: 768px) {
        height: unset;
      }
    `};
`;

const Milestones = styled.div`
  height: 125px;
  background-color: white;
  z-index: 1;
  width: calc(100% - ${COLUMN_PADDING_LEFT});
  padding-left: ${COLUMN_PADDING_LEFT};
  display: none;

  @media screen and (max-width: 1024px) {
    display: none;
  }

  ul {
    & > li {
      background-image: unset !important;
    }

    li.button-back {
      top: 50px !important;
      left: -55px !important;
    }

    li.button-forward {
      top: 50px !important;
      left: -55px !important;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .events-wrapper {
    margin: 0 !important;
    box-sizing: border-box;
    .events {
      position: relative !important;
      left: 6px !important;
      top: 28px !important;
      height: 4px !important;
      border-radius: 4px;
      &:after {
        content: url(${ArrowRight});
        position: absolute;
        right: -6.5px;
        top: -9px;
        z-index: 1;
      }
      .timeline-eventline:nth-child(1) {
        background-color: #c4c4c4 !important;
      }
      .timeline-eventline:nth-child(2) {
        &:after {
          position: absolute;
          right: -21px;
          top: -14px;
          z-index: 1;
        }
      }
      .events-bar {
        li {
          font-weight: bold;
          line-height: 56px;
          font-size: 14px;
          color: #a3a9ac;
          bottom: -84px !important;
          padding-bottom: 0;
          &.past.dot-label {
            span {
              background-color: #d22222 !important;
              &:after {
                position: absolute;
                right: -6px;
                top: -17px;
              }
            }
          }
          &.present.dot-label {
            color: #2d3942;
          }
          &.future.dot-label {
            span {
              border: 2px solid #c4c4c4 !important;
              background: #ffffff !important;
            }
          }
          span {
            bottom: 68px !important;
            width: 14px !important;
            height: 14px !important;
          }
        }
      }
    }
  }
`;

const SwiperContainer = styled.div`
  width: 100%;
  max-height: ${milestoneImageStyle.maxHeight * 2 + 100}px;
  padding: ${COLUMN_PADDING};

  @media screen and (max-width: 1024px) {
    padding: 0 2em;
    overflow: hidden;
  }

  @media screen and (max-width: 768px) {
    max-height: ${milestoneImageStyle.maxHeight * 1.5 + 50}px;
    padding: 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;

  @media screen and (max-width: 768px) {
    flex-flow: column nowrap;
    padding: 0 1em;
    background-color: #fafafa;
    position: relative;
    top: -90px;
  }
`;

const Column = styled.div`
  flex: 1 0 50%;
  padding: ${COLUMN_PADDING};

  @media screen and (max-width: 768px) {
    padding: 1em 0;
    display: flex;
    justify-content: center;
  }

  ${({ fluid }) =>
    fluid &&
    css`
      padding: 0;
    `};
`;

const SliderWrapper = styled(Column)`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    margin-top: 2em;
  }
`;

const OurStoryRow = styled(Row)`
  @media screen and (max-width: 768px) {
    flex-flow: column-reverse nowrap;
  }
`;

const OurStoryDetail = styled(Card)`
  @media screen and (max-width: 768px) {
    padding: 2em;
    background-color: white;
    position: relative;
    bottom: -90px;
  }
`;

const OurStoryImage = styled(Cover)`
  transition: box-shadow 0.5s;

  &:hover {
    box-shadow: 0 0 22px 4px rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 768px) {
    &:hover {
      box-shadow: unset;
    }
  }

  @media screen and (max-width: 768px) {
    min-height: 350px;
    width: 100vw;
    padding: 0 1em;
  }
`;

const WhatWeDid = styled(Row)`
  padding: 3% 0;
`;

const WhatWeDo = styled(Column)`
  padding-left: 8%;
`;

const WhatWeAimFor = styled(Column)`
  padding-left: 2%;
`;

export const query = graphql`
  query fetchOurStory {
    gcms {
      seos(where: { path: "about-us/our-story" }) {
        path
        title
        description
        keywords
      }

      pageAboutUsOurStories(stage: PUBLISHED, first: 1) {
        cover {
          width
          height
          handle
        }
        whatWeKnow {
          ...CTACard
        }
        whatWeDo {
          ...CTACard
        }
        whatWeAimFor {
          ...CTACard
        }
        milestones(orderBy: date_ASC) {
          id
          date
          image {
            url
            width
            height
            handle
          }
          longDescription
        }
      }
    }
  }
`;

export default withI18next()(OurStory);
